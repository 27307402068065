import React from "react"

const Impressum = () => {
  return (
    <div>
      <h2>IMPRESSUM</h2>
      <p>
        <h3>FÜR DEN INHALT VERANTWORTLICH</h3>
        Dolomitenfinanz GmbH <br />
        Tiroler Straße 3, 9991 Dölsach
        <br />
        Tel: +42 (0) 677 617 54454   Mobil: +43 (0) 699 12 300 500
        <br />
        E-Mail: office@dolomitenfinanz.at
        <br />
        Web: www.dolomitenfinanz.at
      </p>

      <p>
        PP Rechtsform: GmbH   <br />
 Fachgruppe: Vermögensberater, Versicherungsmakler
        <br />
        Aufsichtsbehörde: Bezirkshauptmannschaft Lienz <br />
        Mitglied bei Wirtschaftskammer: Tirol <br />
        Berufsgruppe: Vermögensberater, Versicherungsmakler <br />
        Geschäftsführung: Martin Korber <br />
        DVR: 4001911
        <br />
        Firmenbuchnummer: 546125a<br />
        Firmenbuchgericht: Landesgericht Innsbruck
        <br />
        GISA-Zahl gew. Vermögensberatung: 22342249 <br />
        GISA-Zahl Versicherungsmakler: 22349675
      </p>

      <p>
        <h3>OFFENLEGUNG NACH MEDIENGESETZ</h3>
        Medieninhaber: Dolomitenfinanz GmbH
        <br />
        Firmensitz (Ort der Hauptniederl.): Tiroler Straße 3, 9991 Dölsach
        <br />
        Unternehmensgegenstand: Versicherungsmakler und Berater in
        Versicherungsangelegenheiten; Gewerbliche Vermögensberatung gemäß § 94
        Ziffer 75 <br />
        Beteiligungen: Martin Korber, Angelika Korber
        <br />
        Geschäftsführer: Martin Korber <br />
        Blattlinie: Informationen zu Versicherungsprodukten und Beiträge zur
        Versicherungswirtschaft. Informationen zu Versicherungsmakler und
        Vermögensberater.
      </p>
      <p>
        <h3>WERTPAPIERDIENSTLEISTUNG</h3>
        Die Beratung und Vermittlung von Finanzinstrumenten nach dem
        Wertpapieraufsichtsgesetz (WAG) erfolgt als vertraglich gebundener
        Vermittler im Sinne des § 1313a ABGB für die SUPRIS Investment-Service
        GmbH.
      </p>
      <p>
        <h3>RECHTLICHE HINWEISE</h3>
        Eine Haftung oder Garantie für die Aktualität, Richtigkeit und
        Vollständigkeit der zur Verfügung gestellten Informationen kann nicht
        übernommen werden. Gleiches gilt für alle anderen Webseiten, auf die
        mittels Hyperlink verwiesen wird. Die Dolomitenfinanz GmbH ist für den
        Inhalt der Webseiten, die auf Grund einer solchen Verbindung erreicht
        werden, nicht verantwortlich. Inhalt und Struktur der Dolomitenfinanz 
        GmbH - Webseite sind urheberrechtlich geschützt. Die Vervielfältigung von
        Informationen bzw. Daten, insbesondere die Verwendung von Texten (auch
        teilweise) oder Bildern bedarf der vorherigen Zustimmung Korber
        GmbH.
      </p>
      <p>
        <h3>KONZEPT, DESIGN UND PROGRAMMIERUNG</h3>
        <a
          href="http://www.aberjung.com/"
          rel="noopener noreferrer"
          target="_blank"
          className="external"
        >
          aberjung GmbH
        </a>
      </p>
    </div>
  )
}

export default Impressum
